import React from "react"
import Hero from "../Hero"
import SeoIcon from "./SeoIcon"
import Blob from "../../../ServiceLanding/Hero/Blob"

const HeroSeo = ({ heroTextTitle, heroTextSmallText, ctaTitle, heroData }) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            {/* <div className="absolute top-10 left-1/2 z-[1] w-full lg:left-0 lg:top-[-20%] lg:max-w-7xl lg:translate-y-0 lg:-translate-x-1/3">
                <Blob />
            </div> */}
            <SeoIcon />
        </Hero>
    )
}

export default HeroSeo
