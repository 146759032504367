import React from "react"

import piece1 from "@images/serviceHero/seo/piece-1.svg"
import piece2 from "@images/serviceHero/seo/piece-2.svg"
import piece3 from "@images/serviceHero/seo/piece-3.svg"
import piece4 from "@images/serviceHero/seo/piece-4.svg"
import { useRef } from "react"

import { useGSAP } from "@gsap/react"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import gsap from "gsap"

gsap.registerPlugin(ScrollTrigger)

const SeoIcon = () => {
    const container = useRef(null)

    useGSAP(
        () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "0 20%",
                    end: "bottom-=40% 20%",
                    scrub: true
                },
                defaults: { ease: "none" }
            })
            tl.to(
                ".piece-1",
                {
                    bottom: 230,
                    left: 160,
                    rotate: 0
                },
                0
            )
                .to(
                    ".piece-2",
                    {
                        bottom: 350,
                        left: 30,
                        rotate: 0
                    },
                    0
                )
                .to(
                    ".piece-3",
                    {
                        bottom: 200,
                        left: 30,
                        rotate: 0
                    },
                    0
                )
                .to(
                    ".piece-4",
                    {
                        bottom: 160,
                        left: 0,
                        rotate: 0
                    },
                    0
                )
        },
        { scope: container }
    )

    return (
        <div
            ref={container}
            className="relative hidden md:col-[1_/_2] md:row-[2_/_4] xl:block"
        >
            <div className="piece-1 absolute left-[400px] bottom-[500px] z-[2] rotate-12">
                <img alt="seo-piece-1" src={piece1} />
            </div>
            <div className="piece-2 absolute bottom-[800px] z-[2]">
                <img alt="seo-piece-2" src={piece2} />
            </div>
            <div className="piece-3 absolute bottom-[400px] left-[200px] z-[2]">
                <img alt="seo-piece-3" src={piece3} />
            </div>
            <div className="piece-4 absolute bottom-[550px] left-[100px] z-[2]">
                <img alt="seo-piece-4" src={piece4} />
            </div>
        </div>
    )
}

export default SeoIcon
